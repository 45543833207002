
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Post } from "@/types";
import PageHeader from "@/components/ui/PageHeader.vue";
import LoadingScreen from "@/components/ui/LoadingScreen.vue";
import PostNode from "@/components/PostNode.vue";

@Options({
  components: {
    PageHeader,
    LoadingScreen,
    PostNode,
  },
})
export default class HomeView extends Vue {
  posts: Post[] = [];
  store = useStore();
  error = false;
  display = true;

  // Fetch latest posts from server
  async fetchPosts() {
    try {
      const filters = {
        fromRow: 0,
        limit: 2,
      };
      await this.store.dispatch("fetchPosts", filters);
      this.posts = this.store.getters.getPosts(2);
      this.display = false;
    } catch (error) {
      this.error = true;
      //console.error("Failed to fetch posts", error);
    }
  }

  // Call fetch once component is mounted
  mounted() {
    this.fetchPosts();
  }
}
