
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    display: {
      type: Boolean,
      default: true,
    },
  },
});
