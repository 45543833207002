
import { defineComponent, ref, watchEffect } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const isAuthorized = ref(false);

    watchEffect(() => {
      const isAuthenticated = store.getters.getIsAuthenticated;
      const role = store.getters.getRole;
      isAuthorized.value = isAuthenticated && role === "admin";
    });

    return {
      isAuthorized,
    };
  },
});
