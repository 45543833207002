import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid mx-0" }
const _hoisted_2 = { class: "row mx-0 px-0" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppFooter = _resolveComponent("AppFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppHeader),
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          class: "router-link col",
          to: "/"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Home")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "router-link col",
          to: "/posts"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Posts")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "router-link col",
          to: "/projects"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Projects")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "router-link col",
          to: "/about"
        }, {
          default: _withCtx(() => [
            _createTextVNode("About")
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_AppFooter)
  ], 64))
}