
import { Vue } from "vue-class-component";

export default class PageHeader extends Vue {
  pageHeader = "~";
  pageTitle = "mimanet -";
  showReturnButton = false;

  mounted() {
    this.pageHeader = this.$router.currentRoute.value.path;
    if (this.pageHeader == "/") {
      this.pageHeader = "/home";
    }

    this.showReturnButton = this.calculateShowReturnButton();
    this.setPageTitle();
  }

  calculateShowReturnButton() {
    const slashCount: number = (this.pageHeader.match(/\//g) || []).length;
    return slashCount > 1;
  }

  goToPreviousPage() {
    this.$router.go(-1);
  }

  setPageTitle() {
    document.title = this.pageTitle + this.pageHeader.replaceAll("/", " ");
  }
}
