import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e19f34c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container-fluid mx-0 px-0 admin-panel"
}
const _hoisted_2 = { class: "row mx-0 px-0 my-0 view-options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isAuthorized)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "buttons", {}, undefined, true)
        ])
      ]))
    : _createCommentVNode("", true)
}