
import AppHeader from "@/components/layout/AppHeader.vue";
import AppFooter from "@/components/layout/AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
