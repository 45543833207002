import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d726bfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-panel" }
const _hoisted_2 = {
  key: 0,
  class: "user-items"
}
const _hoisted_3 = { class: "user-item" }
const _hoisted_4 = { class: "user-item" }
const _hoisted_5 = { class: "user-item" }
const _hoisted_6 = {
  key: 1,
  class: "user-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isAuthenticatedRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, "Username: " + _toDisplayString(_ctx.username), 1),
          _createElementVNode("div", _hoisted_4, "User role: " + _toDisplayString(_ctx.role), 1),
          _createElementVNode("div", _hoisted_5, "time left: " + _toDisplayString(_ctx.sessionTime) + " min", 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
            class: "button mx-0 my-0"
          }, "Logout")
        ]))
      : _createCommentVNode("", true),
    (_ctx.displayMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.displayMessage), 1))
      : _createCommentVNode("", true)
  ]))
}