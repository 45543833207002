import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-93322ad6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-node container-fluid mx-0 px-0" }
const _hoisted_2 = { class: "row mx-0 px-0" }
const _hoisted_3 = { class: "col-sm mx-0 px-0" }
const _hoisted_4 = { class: "col-sm-auto node-options mx-0 d-flex flex-column justify-content-center" }
const _hoisted_5 = { class: "d-flex flex-sm-column flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PostDisplay = _resolveComponent("PostDisplay")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AdminPanel = _resolveComponent("AdminPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PostDisplay, {
          post: _ctx.post,
          showFullContent: _ctx.showFullContent,
          class: "row mx-0 px-0"
        }, null, 8, ["post", "showFullContent"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AdminPanel, null, {
          buttons: _withCtx(() => [
            _createVNode(_component_router_link, {
              class: "col button ms-0 my-0",
              to: `/edit/post/${_ctx.post.post_id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(" edit ")
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              class: "col button me-0 my-0",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args)))
            }, " delete ")
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, {
            class: "col node-link button mx-sm-0 ms-0",
            to: `/posts/${_ctx.post.post_id}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Go To Post ")
            ]),
            _: 1
          }, 8, ["to"]),
          (_ctx.post.post_content.length > _ctx.maxLength)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "col button mx-sm-0 me-0",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleShowFullContent && _ctx.toggleShowFullContent(...args)))
              }, _toDisplayString(_ctx.showFullContent ? "Show Less" : "Show More"), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}