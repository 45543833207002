
import { Options, Vue } from "vue-class-component";
import UserPanel from "@/components/ui/UserPanel.vue";

@Options({
  components: {
    UserPanel,
  },
})
export default class AppHeader extends Vue {}
