
import { defineComponent, PropType } from "vue";
import { Post } from "@/types";
import sanitizeHtml from "sanitize-html";
import { sanitizedHtmlOptions } from "@/config";

export default defineComponent({
  props: {
    post: {
      type: [Object, null] as PropType<Post | null>,
      required: true,
    },
    showFullContent: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    sanitizedContent(): string {
      if (!this.post) {
        return "";
      }
      if (
        this.post.post_content.length > this.maxLength &&
        !this.showFullContent
      ) {
        return (
          sanitizeHtml(this.post.post_content).slice(0, this.maxLength) + "..."
        );
      }
      return sanitizeHtml(this.post.post_content, sanitizedHtmlOptions);
    },
    maxLength(): number {
      // Set your desired maximum length for truncated content here
      return 280;
    },
  },
});
