import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AdminPanel = _resolveComponent("AdminPanel")!
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_PostDisplay = _resolveComponent("PostDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader),
    (_ctx.post)
      ? (_openBlock(), _createBlock(_component_AdminPanel, { key: 0 }, {
          buttons: _withCtx(() => [
            _createVNode(_component_router_link, {
              class: "col button ms-0 my-0",
              to: `/edit/post/${_ctx.post_id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(" edit post ")
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              class: "col button my-0 me-0",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args)))
            }, " delete post ")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_LoadingScreen, {
      error: _ctx.error,
      display: _ctx.display
    }, null, 8, ["error", "display"]),
    (_ctx.post)
      ? (_openBlock(), _createBlock(_component_PostDisplay, {
          key: 1,
          post: _ctx.post,
          showFullContent: true
        }, null, 8, ["post"]))
      : _createCommentVNode("", true)
  ], 64))
}