
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Project } from "@/types";
import PageHeader from "@/components/ui/PageHeader.vue";
import AdminPanel from "@/components/ui/AdminPanel.vue";
import LoadingScreen from "@/components/ui/LoadingScreen.vue";
import ProjectNode from "@/components/ProjectNode.vue";

@Options({
  components: {
    PageHeader,
    AdminPanel,
    LoadingScreen,
    ProjectNode,
  },
})
export default class ProjectsView extends Vue {
  pageHeader = "/";
  projects: Project[] = [];
  store = useStore();
  error = false;
  display = true;

  // Fetch projects from server
  async fetchProjects() {
    try {
      await this.store.dispatch("fetchProjects");
      this.projects = this.store.getters.getProjects;
      this.display = false;
    } catch (error) {
      this.error = true;
      //console.error("Failed to fetch projects", error);
    }
  }

  // Call fetch once component is mounted
  mounted() {
    this.fetchProjects();
  }
}
