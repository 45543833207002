
import { defineComponent, watch } from "vue";

export default defineComponent({
  data() {
    return {
      userName: this.$store.getters.getUsername,
      passWord: "",
      displayError: null as string | null,
      isAuthenticated: this.$store.getters.getIsAuthenticated,
    };
  },

  mounted() {
    watch(
      () => this.$store.getters.getIsAuthenticated,
      (value) => {
        this.isAuthenticated = value;
      }
    );
  },
  methods: {
    async login() {
      try {
        const errorMessage: string | null = await this.$store.dispatch(
          `login`,
          {
            username: this.userName,
            password: this.passWord,
          }
        );

        if (errorMessage) {
          this.displayError = errorMessage;
        } else {
          this.displayError = null;
          this.passWord = "";
        }
      } catch (error: unknown) {
        this.displayError = "An error occurred during login";
      }
    },
  },
});
