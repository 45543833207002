
import { defineComponent, PropType } from "vue";
import { Project } from "@/types";
import sanitizeHtml from "sanitize-html";
import { sanitizedHtmlOptions } from "@/config";

export default defineComponent({
  props: {
    project: {
      type: [Object, null] as PropType<Project | null>,
      required: true,
    },
  },
  computed: {
    sanitizedContent(): string {
      if (this.project) {
        return sanitizeHtml(this.project.project_content, sanitizedHtmlOptions);
      }
      return "";
    },
  },
});
