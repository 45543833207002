
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Project } from "@/types";
import AdminPanel from "@/components/ui/AdminPanel.vue";

@Options({
  props: {
    project: {},
  },
  components: {
    AdminPanel,
  },
})
export default class ProjectNode extends Vue {
  project!: Project;
  store = useStore();

  // Delete project methods
  confirmDelete() {
    if (
      confirm("Are you sure you want to delete this project from the database?")
    ) {
      this.deleteProjectById();
    }
  }

  async deleteProjectById() {
    try {
      await this.store.dispatch(`deleteProjectById`, this.project.project_id);
      // Redirect to projects page after deletion
      this.$router.push("/projects");
    } catch (error) {
      //console.error("Failed to delete project by id", error);
    }
  }
}
