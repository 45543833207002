
import { Options, Vue } from "vue-class-component";
import PageHeader from "@/components/ui/PageHeader.vue";

@Options({
  components: {
    PageHeader,
  },
})
export default class AboutView extends Vue {}
