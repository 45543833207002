import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c8d8154"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-node container-fluid mx-0 px-0" }
const _hoisted_2 = { class: "row mx-0 px-0" }
const _hoisted_3 = { class: "col-sm mx-0 px-0" }
const _hoisted_4 = { class: "row mx-0 px-0 project-title" }
const _hoisted_5 = {
  key: 0,
  class: "row mx-0 px-0 project-start-date"
}
const _hoisted_6 = { class: "row mx-0 px-0 project-summary" }
const _hoisted_7 = { class: "col-sm-auto node-options mx-0 d-flex flex-column justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AdminPanel = _resolveComponent("AdminPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.project.project_title), 1)
        ]),
        (_ctx.project.project_start_date)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("h3", null, " DATE STARTED: " + _toDisplayString(_ctx.project.project_start_date.toLocaleDateString("en-GB")), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.project.project_description), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_AdminPanel, null, {
          buttons: _withCtx(() => [
            _createVNode(_component_router_link, {
              class: "col button ms-0 my-0",
              to: `/edit/project/${_ctx.project.project_id}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(" edit ")
              ]),
              _: 1
            }, 8, ["to"]),
            _createElementVNode("button", {
              class: "col button me-0 my-0",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirmDelete && _ctx.confirmDelete(...args)))
            }, " delete ")
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "node-link button mx-0",
          to: `/projects/${_ctx.project.project_id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" View Project ")
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}