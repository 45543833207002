import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76050026"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "editor"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "onUpdate:modelValue"]
const _hoisted_4 = ["id", "onUpdate:modelValue"]
const _hoisted_5 = ["id", "disabled", "onUpdate:modelValue"]
const _hoisted_6 = {
  type: "submit",
  class: "button mx-0 my-0"
}
const _hoisted_7 = { class: "mb-0" }
const _hoisted_8 = { class: "preview" }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_ProjectDisplay = _resolveComponent("ProjectDisplay")!
  const _component_PostDisplay = _resolveComponent("PostDisplay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageHeader),
    (_ctx.isAuthorized)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("form", {
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
            class: "edit-form"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item, (value, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                class: "form-group"
              }, [
                _createElementVNode("label", { for: key }, _toDisplayString(key), 9, _hoisted_2),
                (_ctx.keyType(key) === 'textarea')
                  ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                      key: 0,
                      rows: "16",
                      id: key,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.item[key]) = $event),
                      class: "form-control",
                      required: ""
                    }, null, 8, _hoisted_3)), [
                      [_vModelText, _ctx.item[key]]
                    ])
                  : (_ctx.keyType(key) === 'date' && _ctx.objectType !== 'post')
                    ? (_openBlock(), _createBlock(_component_Datepicker, {
                        key: 1,
                        id: key,
                        modelValue: new Date(_ctx.item[key]),
                        "onUpdate:modelValue": ($event: any) => (_ctx.item[key] = $event),
                        inputFormat: "dd/MM/yyyy",
                        class: "form-control"
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue"]))
                    : (_ctx.keyType(key) === 'checkbox')
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 2,
                          id: key,
                          type: "checkbox",
                          "onUpdate:modelValue": ($event: any) => ((_ctx.item[key]) = $event),
                          "true-value": "yes",
                          "false-value": "no",
                          class: "form-check-input"
                        }, null, 8, _hoisted_4)), [
                          [_vModelCheckbox, _ctx.item[key]]
                        ])
                      : _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 3,
                          id: key,
                          disabled: _ctx.keyType(key) === 'id' || _ctx.keyType(key) === 'date',
                          "onUpdate:modelValue": ($event: any) => ((_ctx.item[key]) = $event),
                          class: "form-control",
                          required: ""
                        }, null, 8, _hoisted_5)), [
                          [_vModelText, _ctx.item[key]]
                        ])
              ]))
            }), 128)),
            _createElementVNode("button", _hoisted_6, " Save " + _toDisplayString(_ctx.objectType), 1)
          ], 32),
          _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.objectType) + " Preview", 1),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.objectType === 'project')
              ? (_openBlock(), _createBlock(_component_ProjectDisplay, {
                  key: 0,
                  project: (_ctx.convertObject() as Project)
                }, null, 8, ["project"]))
              : (_ctx.objectType === 'post')
                ? (_openBlock(), _createBlock(_component_PostDisplay, {
                    key: 1,
                    post: (_ctx.convertObject() as Post),
                    showFullContent: true
                  }, null, 8, ["post"]))
                : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, "Unauthorized access"))
  ], 64))
}