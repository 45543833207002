
import { Options, Vue } from "vue-class-component";
import PageHeader from "@/components/ui/PageHeader.vue";
import LoginForm from "@/components/LoginForm.vue";

@Options({
  components: {
    PageHeader,
    LoginForm,
  },
})
export default class AdminView extends Vue {}
