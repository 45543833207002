
import { Options, Vue } from "vue-class-component";
import { useStore } from "vuex";
import { Post } from "@/types";
import PostDisplay from "@/components/PostDisplay.vue";
import AdminPanel from "@/components/ui/AdminPanel.vue";

@Options({
  props: {
    post: {},
  },
  components: {
    PostDisplay,
    AdminPanel,
  },
})
export default class PostNode extends Vue {
  post!: Post;
  maxLength = 100;
  showFullContent = false;
  store = useStore();

  toggleShowFullContent() {
    this.showFullContent = !this.showFullContent;
  }

  // Delete post methods
  confirmDelete() {
    if (
      confirm("Are you sure you want to delete this post from the database?")
    ) {
      this.deletePostById();
    }
  }

  async deletePostById() {
    try {
      await this.store.dispatch(`deletePostById`, this.post.post_id);
      // Redirect to posts page after deletion
      this.$router.push("/posts");
    } catch (error) {
      //console.error("Failed to delete post by id", error);
    }
  }
}
