import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c4ca7b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pageheader" }
const _hoisted_2 = { class: "row mx-0 px-0" }
const _hoisted_3 = { class: "col mx-0 px-0" }
const _hoisted_4 = { class: "col-auto mx-0 px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.pageHeader), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.showReturnButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "button mx-0 my-0",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToPreviousPage && _ctx.goToPreviousPage(...args)))
            }, " Return "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}